@import '@elanco/component-library-v2/dist/styles.css';
@import 'tailwindcss/utilities';
@import "../_new-code/products/flexible-web-toolkit/components/carousel/slick-slider.css";
@import "../_new-code/products/flexible-web-toolkit/components/carousel/dynamic-card-lister.css";
@import "../_new-code/products/flexible-web-toolkit/components/carousel/full-width-carousel.css";

html,
body {
	@apply m-0 p-0;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica Neue,
		sans-serif;
}

#__next {
	@apply flex min-h-screen flex-col;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	@apply box-border;
}

.faq-content a:not(.btn) {
	@apply font-bold text-primary underline hover:text-primary-dark active:text-secondary !important;
}

.preview-mode {
	@apply mt-10;
}

.preview-mode .sticky-outer-wrapper.active > .sticky-inner-wrapper {
	@apply !top-10;
}

/* Override default white text color for these brands */
.brandtext-secondary,
.brandtext-interceptor,
.brandtext-trifexis,
.brandtext-experior,
.brandtext-adtab_white,
.brandtext-adtab_yellow,
.brandtext-prefender {
	@apply text-black;
}

/* PET brand date input fields */

[type='date']::-webkit-inner-spin-button,
[type='date']::-webkit-calendar-picker-indicator {
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	left: -20px;
}

/* PET brand rich text fields */

.content-rich-text li {
	list-style-type: disc;
}
.content-rich-text h2 {
	margin-bottom: 12px;
}
.content-rich-text h2 {
	margin-left: -16px;
}

/* React Schema form generic styles */
.rjsf-form .form-group {
	margin: 10px 0;
}

.rjsf-form .form-control {
	border: 1.5px solid #0072ce;
}

.rjsf-form .control-label {
	font-weight: bold;
	font-size: 12px;
	margin-bottom: 5px;
}

.rjsf-form select {
	background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right 1rem center;
	background-size: 18px 16px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.rjsf-form .error-detail {
	list-style: none;
	padding-left: 0;
	margin: 5px 0;
}

.rjsf-form .text-danger,
.rjsf-form-error {
	color: #ff0033;
	font-weight: 500;
	margin: 5px 0;
}

.rjsf-form-tabs div {
	padding: 0;
}

.rjsf-form-tabs .content {
	/* Overriding tab styles */
	box-shadow: none !important;
}

.rjsf-form-tab {
	padding: 15px;
	/* Overriding tab styles */
	box-shadow: none !important;
}

.bg-category-behaviour {
	background: #d43900;
}
.bg-category-new_pets {
	background: #bd2ca2;
}
.bg-category-lifestyle {
	background: #b50000;
}
.bg-category-health_and_care {
	background: #138187;
}
.bg-category-parasites {
	background: #3a76bb;
}

/* Loading Spinner */

.lds-spinner {
	@apply relative inline-block h-20 w-20;
	color: official;
}
.lds-spinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	@apply absolute left-[37px] top-[3px] block h-[18px] w-[6px] rounded-[20%] content-[''];
}
.lds-spinner.blue div:after {
	@apply bg-blue-500;
}
.lds-spinner.green div:after {
	@apply bg-green-600;
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.modal-open {
	@apply xl:overflow-hidden;
}

/* CDC */

.gigya-screen {
	@apply max-w-full !p-0;
}

/* Account Dropdown  */

.account-dropdown {
	@apply relative hidden lg:block;
}
.account-dropdown button {
	@apply flex items-center space-x-1 px-2;
}
.account-dropdown button:after {
	@apply inline-block h-[10px] w-5 bg-center bg-no-repeat content-[''];
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4zMzMiIGhlaWdodD0iNi42NjciPjxwYXRoIGQ9Im0wIDAgNi42NjcgNi42NjdMMTMuMzMzIDBaIiBmaWxsPSIjMDIyNTNlIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
}
.account-dropdown button:hover + div {
	@apply flex;
}
.account-dropdown .nav-link-container {
	@apply absolute right-0 hidden w-[180px] flex-col rounded-md bg-white drop-shadow-lg transition-all duration-200 ease-in-out hover:flex;
}
.account-dropdown .nav-link-container ul {
	@apply rounded-md border border-gray-200;
}
.account-dropdown .nav-link-container ul li {
	@apply border-b px-4;
}
.account-dropdown .nav-link-container ul li:hover {
	@apply bg-gray-100;
}
.account-dropdown .nav-link-container ul li:last-child {
	@apply rounded-bl-md rounded-br-md border-0;
}

.shadow-primary,
.shadow-secondary {
	box-shadow: none;
}

/* Any styling that should be applied to default elements e.g. output of a RTE */
.rich-content h1,
.rich-content h2,
.rich-content h3,
.rich-content h4,
.rich-content h5,
.rich-content p,
.rich-content ul,
.rich-content ol {
	@apply mb-4;
}

.rich-content .table-container {
	@apply mx-auto;
}

.rich-content table {
	@apply border-separate text-center shadow-secondary;
}

.rich-content thead tr {
	@apply bg-blue-700;
}

.rich-content thead th {
	@apply sticky top-0 md:static;
}

.rich-content img {
	@apply mx-auto;
}

.rich-content tbody tr:nth-child(odd) {
	@apply bg-gray-200;
}

.rich-content tbody tr:nth-child(even) {
	@apply bg-gray-100;
}

.rich-content th,
.rich-content td {
	@apply min-w-[130px] border-r-2 border-white bg-inherit p-4 first:sticky first:left-0 first:z-[1] last-of-type:border-none md:min-w-[auto] first:md:static;
}

.rich-content th {
	@apply text-white first:z-[1];
}

.rich-content .remove-margin {
	@apply mb-0;
}

.rich-content.content--remove-bottom-margin :last-child {
	@apply mb-0;
}

.rich-content.content--remove-bottom-margin > :last-child {
	@apply mb-0;
}

.rich-content.content--no-width-restriction {
	@apply w-full max-w-none;
}

.rich-content a:not(.btn) {
	@apply font-bold text-primary underline hover:text-primary-dark active:text-secondary;
}

.rich-content ul {
	@apply list-disc pl-[1.3rem];
}

.rich-content ol {
	@apply list-decimal pl-[1.3rem];
}

.rich-content ul ul {
	@apply mt-2 pl-8;
}

.rich-content li {
	@apply mb-2;
}

.rich-content .table-container {
	@apply md:flex md:w-full md:items-start md:overflow-x-scroll;
}

.rich-content .table-container table {
	@apply shadow-none;
}

.text-center ul {
	@apply list-inside;
}

/* column card lister for 70-30 size of raw */
@media screen and (min-width: 768px) {
	.thirty .column {
		@apply p-0;
	}

	.thirty .column .article-blockhellotehrerefasdnfkasnkjdfa {
		@apply px-0;
	}
	.thirty .column .article-card {
		@apply m-0 w-full;
	}
}

.faq-block svg {
	--tw-rotate: 0deg;
}

.text-white-links .rich-content a:not(.btn) {
	@apply text-white;
}

.text-black-links .rich-content a:not(.btn) {
	@apply text-black;
}

.react-calendar__navigation {
  @apply flex bg-[#D0E9C7] font-semibold rounded-full;
}

.react-calendar__navigation__label {
  @apply font-semibold text-base;
}

.react-calendar__viewContainer {
  @apply mt-4;
}

.react-calendar__tile {
@apply h-14 w-14 sm:h-16 sm:w-16 md:h-14 md:w-14 lg:h-16 lg:w-16;
  & > abbr {
    @apply relative z-10;
  }
}

.react-calendar__tile:focus {
	@apply outline-none;
}

.react-calendar__month-view__weekdays {
  @apply pt-1 mt-3 text-center text-gray-500;
  & abbr {
    text-decoration: none;
  }
}

.active.has-reminder {
  @apply text-secondary;
}

.flex-slider .slick-track {
	display: flex;
}

.flex-slider .slick-slide {
	height: inherit;
}

.flex-slider .slick-slide > div {
	height: 100%;
}
